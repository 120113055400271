import Head from 'next/head';
import Image from 'next/image';

import Slider from '@/Components/Slider';
import { motion } from 'framer-motion';
import Counter from '@/Components/Counter';

import dhanuka from '../assets/colab/dhanuka.png';
import euro_fruits from '../assets/colab/euro_fruits.png';
import freshtrop from '../assets/colab/freshtrop.png';
import hml from '../assets/colab/hml.jpeg';
import iffco from '../assets/colab/iffco.png';
import imported_fruits from '../assets/colab/imported_fruits.png';
import itc from '../assets/colab/itc.png';
import kalya from '../assets/colab/kalya.png';
import mahindra from '../assets/colab/mahindra.png';
import orchardly from '../assets/colab/orchardly.png';
import synthite from '../assets/colab/synthite.png';

import { CropsArray } from '@/Components/crops';
import { FarmerOpinionArray } from '../Components/farmerOpinion';
import { StoriesArray } from '@/Components/Story';
//illustration
import DeviceImage from '../assets/nerokairo.png';
import algo from '../assets/Optimized-algo.png';
import critical_insights from '../assets/critical_insights.png';

import droneVideo from '../assets/story_image/drone_video.mp4';

import Link from 'next/link';

import MainHeading from '@/Components/MainHeading';
import SubHeading from '@/Components/SubHeading';
import ContentTitle from '@/Components/ContentTitle';
import ContentText from '@/Components/ContentText';

import { Button } from '@/Components/Button';

const clientsLogo = [
  dhanuka,
  euro_fruits,
  freshtrop,
  hml,
  iffco,
  imported_fruits,
  itc,
  kalya,
  mahindra,
  orchardly,
  synthite,
];

const HowCardArray = [
  {
    title: 'Device',
    content:
      'Fyllo device installed at the farm captures data from soil, climate and crop canopy and sends it to Fyllo server',
    image: DeviceImage,
    link: '/product/hardware',
  },
  {
    title: 'Data',
    content: 'Farm data from the device gets processed using proprietary analytics algorithms on our cloud server ',
    image: algo,
    link: '/about/data-collection',
  },
  {
    title: 'Critical Insights',
    content: 'Insights on growing the best quality crops are sent to mobile App and Dashboards',
    image: critical_insights,
    link: '/product/software',
  },
];

export default function Home() {
  const MainContainer = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 3.5,
        staggerChildren: 9,
      },
    },
  };
  const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.2,
        staggerChildren: 0.1,
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };
  const solutionPosition = 'Data Backed Agriculture Solutions.'.indexOf('So');

  return (
    <>
      <Head>
        <title>Fyllo</title>
        <meta
          name="description"
          content="Fyllo helps farmers take precise decisions and increase farm productivity. We install devices on farms that provide farmers with access to real-time farm data, insights, and analytics through our mobile app."
        />
        <meta name="og:type" content="website"></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="keywords"
          content="Agriculture, IoT,Fyllo, Indian farmers, Smart farms, Smart Farming, New Techniques in farming, Fertilizers,Fertilizers Application,Ag revolution, Agritech, Agritech startup, AI, Smart Agriculture, Precision Agriculture, Soil Moisture, weather Prediction, Disease Prediction, Data Driven Agriculture"
        />
        <meta name="author" content="fyllo - Redefining India’s agriculture with Digital  Innovation"></meta>
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@site_account" />
        <meta name="twitter:creator" content="@fylloTech" />
        <meta name="twitter:url" content="https://twitter.com/fyllotech?lang=en" />
        <meta name="twitter:title" content="Fyllo by AgriHawk Technologies" />
        <link rel="manifest" href="/site.webmanifest" />
        <script
          src="https://www.chatbase.co/embed.min.js"
          id="_WY8P6CPX1OlX7zC0mJgy"
          defer>
        </script>
      </Head>
      <main className="">
        <div className="w-full z-[6] h-[55vw] md:h-[45vw] absolute grid grid-cols-1 place-items-center ">
          <div className="w-full h-full grid place-items-center ">
            <div className="text-base  text-black md:text-white text-center">
              <motion.div variants={MainContainer} initial="hidden" animate="visible">
                <motion.p className="text-white text-xl" variants={container} initial="hidden" animate="visible">
                  {'Next Gen Agri Intelligence Platform'.split('').map((ch, i) => {
                    return (
                      <motion.span className="text-white text-extrabold" key={i} variants={item}>
                        {ch}
                      </motion.span>
                    );
                  })}
                </motion.p>
                <motion.p
                  className="text-white mt-2  mb-4 text-xl font-extrabold tracking-tight leading-none md:text-3xl xl:text-4xl  text-center"
                  variants={container}
                >
                  {'Data Backed Agriculture Solutions.'.split('').map((ch, i) => {
                    return (
                      <motion.span
                        className={
                          'text-white '
                          // +
                          // (
                          //   i>=solutionPosition?
                          //   "text-white underline underline-offset-3 decoration-8 decoration-green-600":"")
                        }
                        key={i}
                        variants={item}
                      >
                        {ch}
                      </motion.span>
                    );
                  })}
                </motion.p>
              </motion.div>
            </div>
          </div>
        </div>
        <div className="w-full md:mt-2 z-[5] h-[56.2vw] md:h-[56.2vw] absolute place-items-center bg-gradient-to-b from-transparent to-zinc-900"></div>
        {/* <div className="w-full -mt-12 z-[2] md:hidden grid grid-cols-1 place-items-center">
          <Image className="h-[72vw] w-full object-cover" src={droneVideo_gif} alt="field"/>
        </div> */}
        <video className="h-[56.2vw] md:h-[56.2vw] w-full " autoPlay loop muted playsInline>
          <source autoPlay src={droneVideo} />
          Your browser does not support the video tag.
        </video>

        <section id="howItWorks" className="pt-16 items-center">
          <div className=" flex flex-col  md:pt-12">
            <MainHeading text=" How It Works" />
            <div className="max-w-screen-xl self-center grid place-items-center grid-cols-1 md:grid-cols-3 gap-5 xl:gap-20 p-10">
              {HowCardArray.map((it, i) => {
                return (
                  <div
                    key={i}
                    className="max-w-sm bg-white border border-gray-200 rounded-lg shadow hover:shadow-2xl transition-all hover:scale-105"
                  >
                    <figure>
                      <Image
                        // loading={'lazy'}
                        className="w-30 rounded-t-lg aspect-video object-cover"
                        src={it?.image}
                        width={600}
                        height={400}
                        alt={it.title}
                        priority
                        sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
                      />
                    </figure>
                    <div className="p-5">
                      <a href="#">
                        <h2 className="mb-2 text-2xl font-bold tracking-tight text-gray-900">{it.title}</h2>
                      </a>
                      <p className=" mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl min-h-[80px]">
                        {it.content}
                      </p>
                      <Link
                        href={it.link}
                      // className="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-fyllo-600 rounded-lg hover:bg-fyllo-800"
                      >
                        <button className="btn btn-sm btn-outline border-gray-200 hover:border-gray-400 border-2 hover:bg-transparent">
                          <p className="normal-case text-xs  text-fyllo-800"> Know More</p>
                          <svg
                            aria-hidden="true"
                            className="w-3 h-3 ml-2 -mr-1 fill-fyllo-800"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="text-white bg-gradient-to-t from-green-800 to-green-900  grid grid-cols-2 md:grid-cols-4 gap-2 gap-y-8 justify-between px-4 md:px-10 lg:px-20 xl:px-32 py-4 md:py-16">
          <div className="grid place-items-center ">
              <h3 className="text-xl md:text-5xl">
                <Counter from={0} to={80} postContent={'%'} />
              </h3>
              <div className="text-white text-sm md:mt-3 text-center md:text-base">Export Quality Produce</div>
            </div>
            <div className="grid place-items-center ">
              <h3 className="text-xl md:text-5xl">
                <Counter from={0} to={20} postContent={'%'} />
              </h3>
              <div className="text-white text-sm md:mt-3 text-center md:text-base">Increase in Production</div>
            </div>
            <div className="grid place-items-center">
              <h3 className="text-xl md:text-5xl">
                <Counter from={0} to={25} postContent={'%'} />
              </h3>
              <div className="text-white text-sm md:mt-3 text-center  md:text-base"> Reduction In Input Cost</div>
            </div>
            <div className="grid place-items-center">
              <h3 className="text-xl md:text-5xl">
                <Counter from={0} to={33} postContent={'%'} />
              </h3>
              <div className="text-white text-sm md:mt-3 text-center  md:text-base"> Reduction In Water Usage</div>
            </div>
          </div>
        </section>
        <section className="clients bg-white flex flex-col items-center justify-center pt-12 ">
          <MainHeading text=" Our Clients" />
          <div className=" max-w-screen-xl place-items-center grid grid-cols-2 md:grid-cols-5 gap-y-6 gap-x-6  px-4 py-4 md:gap-5 lg:gap-10 xl:gap-20 md:px-10  md:py-20">
            {clientsLogo.map((client, i) => {
              return (
                <div
                  key={'images-' + i}
                  className={
                    (i === clientsLogo.length - 1 ? 'md:col-start-3 md:col-end-4 ' : '') +
                    'w-full h-[22vh] bg-white  grid place-items-center'
                  }
                >
                  <Image
                    loading={'lazy'}
                    src={client}
                    className="p-[1vh] h-[20vh] object-contain hover:scale-125 transition-all"
                    alt={'client'}
                    srcSet=""
                    sizes="(max-width: 768px) 100vw,
              (max-width: 1200px) 50vw,
              33vw"
                  />
                </div>
              );
            })}
          </div>
        </section>

        <section className="p-10 bg-slate-50">
          <MainHeading text="What They Say About Us" />
          <div className="farmers_opinion_slider_container">
            <Slider imgArray={FarmerOpinionArray} />
          </div>
        </section>

        <section className="bg-white p-10">
          <MainHeading text="Crops" />
          <SubHeading text="Maximize your crop potential with our cutting-edge agronomy model, tailored to your unique vegetable and fruit needs." />
          <div className="p-2">
            <Slider
              sliderName={'fruits'}
              style={{
                padding: '50px',
              }}
              imgArray={CropsArray}
              slidesPerView={5}
            />
          </div>
        </section>

        <section className="w-screen grid grid-cols-1 md:grid-cols-3 p-4 md:p-12 bg-white ">
          <div className="pt-24">
            {/* <h3 className="text-fyllo-800 mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-4xl">
            
            </h3> */}
            <ContentTitle text="Explore our Story" />
            <ContentText text=" Fyllo's story is that of precision, growth and impact. Read out our amazing journey as in news." />
          </div>
          <div className="md:col-span-2">
            <Slider sliderName={'explore'} slidesPerView={2} imgArray={StoriesArray} />
          </div>
        </section>
      </main>
    </>
  );
}
