import React from 'react';

export default function ContentTitle({ text = '', styles = '', children }) {
  return (
    <div
      className={
        'mb-2 md:mb-4 text-xl md:text-xl lg:text-xl text-start  font-bold tracking-tight text-green-800 capitalize  ' +
        styles
      }
    >
      {text} {children}
    </div>
  );
}
